// prefer default export if available
const preferDefault = m => (m && m.default) || m

exports.components = {
  "component---cache-caches-gatsby-plugin-offline-app-shell-js": () => import("./../../caches/gatsby-plugin-offline/app-shell.js" /* webpackChunkName: "component---cache-caches-gatsby-plugin-offline-app-shell-js" */),
  "component---src-pages-404-jsx": () => import("./../../../src/pages/404.jsx" /* webpackChunkName: "component---src-pages-404-jsx" */),
  "component---src-pages-agreements-legal-jsx": () => import("./../../../src/pages/agreements/legal.jsx" /* webpackChunkName: "component---src-pages-agreements-legal-jsx" */),
  "component---src-pages-agreements-refund-jsx": () => import("./../../../src/pages/agreements/refund.jsx" /* webpackChunkName: "component---src-pages-agreements-refund-jsx" */),
  "component---src-pages-agreements-terms-jsx": () => import("./../../../src/pages/agreements/terms.jsx" /* webpackChunkName: "component---src-pages-agreements-terms-jsx" */),
  "component---src-pages-designer-api-jsx": () => import("./../../../src/pages/designer/api.jsx" /* webpackChunkName: "component---src-pages-designer-api-jsx" */),
  "component---src-pages-designer-editor-jsx": () => import("./../../../src/pages/designer/editor.jsx" /* webpackChunkName: "component---src-pages-designer-editor-jsx" */),
  "component---src-pages-designer-elements-jsx": () => import("./../../../src/pages/designer/elements.jsx" /* webpackChunkName: "component---src-pages-designer-elements-jsx" */),
  "component---src-pages-designer-expressions-jsx": () => import("./../../../src/pages/designer/expressions.jsx" /* webpackChunkName: "component---src-pages-designer-expressions-jsx" */),
  "component---src-pages-designer-limitations-jsx": () => import("./../../../src/pages/designer/limitations.jsx" /* webpackChunkName: "component---src-pages-designer-limitations-jsx" */),
  "component---src-pages-designer-money-jsx": () => import("./../../../src/pages/designer/money.jsx" /* webpackChunkName: "component---src-pages-designer-money-jsx" */),
  "component---src-pages-designer-premium-jsx": () => import("./../../../src/pages/designer/premium.jsx" /* webpackChunkName: "component---src-pages-designer-premium-jsx" */),
  "component---src-pages-designer-publish-jsx": () => import("./../../../src/pages/designer/publish.jsx" /* webpackChunkName: "component---src-pages-designer-publish-jsx" */),
  "component---src-pages-designer-tags-jsx": () => import("./../../../src/pages/designer/tags.jsx" /* webpackChunkName: "component---src-pages-designer-tags-jsx" */),
  "component---src-pages-designer-tutorials-jsx": () => import("./../../../src/pages/designer/tutorials.jsx" /* webpackChunkName: "component---src-pages-designer-tutorials-jsx" */),
  "component---src-pages-faq-alreadypaid-jsx": () => import("./../../../src/pages/faq/alreadypaid.jsx" /* webpackChunkName: "component---src-pages-faq-alreadypaid-jsx" */),
  "component---src-pages-faq-cantfind-jsx": () => import("./../../../src/pages/faq/cantfind.jsx" /* webpackChunkName: "component---src-pages-faq-cantfind-jsx" */),
  "component---src-pages-faq-notunlocked-jsx": () => import("./../../../src/pages/faq/notunlocked.jsx" /* webpackChunkName: "component---src-pages-faq-notunlocked-jsx" */),
  "component---src-pages-faq-weather-jsx": () => import("./../../../src/pages/faq/weather.jsx" /* webpackChunkName: "component---src-pages-faq-weather-jsx" */),
  "component---src-pages-index-jsx": () => import("./../../../src/pages/index.jsx" /* webpackChunkName: "component---src-pages-index-jsx" */),
  "component---src-pages-user-buydesign-jsx": () => import("./../../../src/pages/user/buydesign.jsx" /* webpackChunkName: "component---src-pages-user-buydesign-jsx" */),
  "component---src-pages-user-collections-jsx": () => import("./../../../src/pages/user/collections.jsx" /* webpackChunkName: "component---src-pages-user-collections-jsx" */),
  "component---src-pages-user-demo-jsx": () => import("./../../../src/pages/user/demo.jsx" /* webpackChunkName: "component---src-pages-user-demo-jsx" */),
  "component---src-pages-user-getstarted-jsx": () => import("./../../../src/pages/user/getstarted.jsx" /* webpackChunkName: "component---src-pages-user-getstarted-jsx" */),
  "component---src-pages-user-install-jsx": () => import("./../../../src/pages/user/install.jsx" /* webpackChunkName: "component---src-pages-user-install-jsx" */),
  "component---src-pages-user-unlock-jsx": () => import("./../../../src/pages/user/unlock.jsx" /* webpackChunkName: "component---src-pages-user-unlock-jsx" */)
}

